import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/components/auth/AuthContext";
import { useCart } from "src/components/cart/CartProvider";
import Button from "src/components/form-components/Button";
import Checkbox from "src/components/form-components/Checkbox";
import InputField from "src/components/form-components/InputField";
import TextArea from "src/components/form-components/TextArea";
import Loading from "src/components/loading/Loading";
import { Order, OrderPostResponse } from "src/data/Order";
import { PaymentType } from "src/data/PaymentType";
import { ProfileData } from "src/data/Profile";
import { ShippingType } from "src/data/ShippingType";
import apiService from "src/shared/apiService/ApiService";

function Checkout() {
  const { cart, cartTotalPrice, clearCart, cartLoaded } = useCart();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  const [shippingTypes, setShippingTypes] = useState<ShippingType[]>();
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>();

  const [orderForm, setOrderForm] = useState<Partial<Order>>({
    same_shipment_and_billing: 0,
  });
  const [orderFormErrors, setOrderFormErrors] = useState<{
    [key: string]: string;
  }>();

  useEffect(() => {
    getShippingTypes();
    getPaymentTypes();
    if (isLoggedIn) {
      apiService.get<ProfileData>("/profile").then((response: ProfileData) => {
        setOrderForm((prev) => ({
          ...prev,
          email: response.email,
          phone_number: response.phone_number,
          shipment_last_name: response.last_name,
          shipment_first_name: response.first_name,
          shipment_address_zip_code: response.zip_code,
          shipment_address_city: response.city,
          shipment_address_street_address: response.street_address,
          shipment_address_floor_number: response.floor_number,
          shipment_address_door_number: response.door_number,
        }));
      });
    }
  }, []);

  useEffect(() => {
    if (cartLoaded && cart.length === 0) {
      navigate("/");
    }
    setOrderForm((prev) => ({
      ...prev,
      products: cart.map((cartItem) => ({
        product_id: cartItem.product_id,
        quantity: cartItem.quantity,
      })),
    }));
  }, [cart]);

  function getShippingTypes() {
    apiService
      .get<ShippingType[]>("/shipping/types")
      .then((response: ShippingType[]) => {
        setShippingTypes(response);
      });
  }

  function getPaymentTypes() {
    apiService
      .get<PaymentType[]>("/payment/types")
      .then((response: PaymentType[]) => {
        setPaymentTypes(response);
      });
  }

  function changeSameShipmentAndBilling(state: boolean) {
    setOrderForm((prev) => ({
      ...prev,
      same_shipment_and_billing: state ? 1 : 0,
    }));

    if (state) {
      setOrderForm((prev) => ({
        ...prev,
        billing_first_name: orderForm?.shipment_first_name,
        billing_last_name: orderForm?.shipment_last_name,
        billing_address_zip_code: orderForm?.shipment_address_zip_code,
        billing_address_city: orderForm?.shipment_address_city,
        billing_address_street_address:
          orderForm?.shipment_address_street_address,
        billing_address_floor_number: orderForm?.shipment_address_floor_number,
        billing_address_door_number: orderForm?.shipment_address_door_number,
      }));
    }
  }

  function getShipmentPrice() {
    return (
      shippingTypes?.find(
        (shipmentType) => shipmentType.id === orderForm?.shipment_type_id
      )?.price ?? 0
    );
  }

  function getPaymentPrice() {
    return (
      paymentTypes?.find(
        (paymentType) => paymentType.id === orderForm?.payment_type_id
      )?.price ?? 0
    );
  }

  function getFinalPrice() {
    const shipmentPrice =
      shippingTypes?.find(
        (shipmentType) => shipmentType.id === orderForm?.shipment_type_id
      )?.price ?? 0;
    const paymentPrice =
      shippingTypes?.find(
        (shipmentType) => shipmentType.id === orderForm?.shipment_type_id
      )?.price ?? 0;
    return cartTotalPrice + shipmentPrice + paymentPrice;
  }

  function submitOrder() {
    setOrderFormErrors(undefined);
    apiService
      .post<OrderPostResponse>("/order", orderForm)
      .then((response: OrderPostResponse) => {
        clearCart();
        navigate("/orderOverview", {
          state: {
            order_data: response.message.order_data[0],
          },
        });
      })
      .catch((error) => {
        setOrderFormErrors(error.message);
      });
  }

  return (
    <div className="p-2 flex flex-col items-center w-full">
      <div className="flex flex-row gap-10 justify-center max-w-[80%]">
        <div className="bg-bg-primary2 px-3 py-2 rounded-sm w-fit shadow h-fit">
          <div className="flex flex-col w-fit gap-5">
            <span className="font-bold text-lg mt-1">Személyes adatok</span>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="E-mail cím"
                value={orderForm?.email}
                change={(value) =>
                  setOrderForm((prev) => ({ ...prev, email: value }))
                }
                errorKey={orderFormErrors?.email}
              ></InputField>
              <InputField
                placeholder="Telefon"
                value={orderForm?.phone_number}
                change={(value) =>
                  setOrderForm((prev) => ({ ...prev, phone_number: value }))
                }
                errorKey={orderFormErrors?.phone_number}
              ></InputField>
            </div>
          </div>
          <div className="flex flex-col w-fit gap-2">
            <span className="font-bold text-lg mt-5">Szállítási adatok</span>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Vezetéknév"
                value={orderForm?.shipment_last_name}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_last_name: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_last_name}
              ></InputField>
              <InputField
                placeholder="Keresznév"
                value={orderForm?.shipment_first_name}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_first_name: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_first_name}
              ></InputField>
            </div>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Irányítószám"
                type="number"
                value={orderForm?.shipment_address_zip_code}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_zip_code: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_zip_code}
              ></InputField>
              <InputField
                placeholder="Település"
                value={orderForm?.shipment_address_city}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_city: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_city}
              ></InputField>
            </div>
            <InputField
              placeholder="Utca, házszám"
              value={orderForm?.shipment_address_street_address}
              change={(value) =>
                setOrderForm((prev) => ({
                  ...prev,
                  shipment_address_street_address: value,
                }))
              }
              errorKey={orderFormErrors?.shipment_address_street_address}
            ></InputField>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Emelet"
                type="number"
                value={orderForm?.shipment_address_floor_number}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_floor_number: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_floor_number}
              ></InputField>
              <InputField
                placeholder="Ajtó"
                type="text"
                value={orderForm?.shipment_address_door_number}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_door_number: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_door_number}
              ></InputField>
            </div>
          </div>
          <div className="flex flex-col w-fit gap-2">
            <span className="font-bold text-lg mt-5">Számlázási adatok</span>
            <Checkbox
              checked={orderForm?.same_shipment_and_billing === 1}
              checkCallback={(state: boolean) =>
                changeSameShipmentAndBilling(state)
              }
            >
              Megegyezik a szállítási adatokkal.
            </Checkbox>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Vezetéknév"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_last_name
                    : orderForm?.billing_last_name
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_last_name: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_last_name}
              ></InputField>
              <InputField
                placeholder="Keresznév"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_first_name
                    : orderForm?.billing_first_name
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_first_name: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_first_name}
              ></InputField>
            </div>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Irányítószám"
                type="number"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_zip_code
                    : orderForm?.billing_address_zip_code
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_zip_code: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_zip_code}
              ></InputField>
              <InputField
                placeholder="Település"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_city
                    : orderForm?.billing_address_city
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_city: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_city}
              ></InputField>
            </div>
            <InputField
              placeholder="Utca, házszám"
              value={
                orderForm?.same_shipment_and_billing === 1
                  ? orderForm?.shipment_address_street_address
                  : orderForm?.billing_address_street_address
              }
              change={(value) =>
                setOrderForm((prev) => ({
                  ...prev,
                  billing_address_street_address: value,
                }))
              }
              errorKey={orderFormErrors?.billing_address_street_address}
            ></InputField>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Emelet"
                type="number"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_floor_number
                    : orderForm?.billing_address_floor_number
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_floor_number: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_floor_number}
              ></InputField>
              <InputField
                placeholder="Ajtó"
                type="text"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_door_number
                    : orderForm?.billing_address_door_number
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_door_number: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_door_number}
              ></InputField>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-10 flex-1 justify-center h-fit">
          <div className="bg-bg-primary2 shadow rounded-sm p-2">
            <span className="font-bold text-lg mt-1">Szállítási forma </span>
            {orderFormErrors?.shipment_type_id && (
              <div className="text-color-error text-sm mb-2">
                {t(`error.${orderFormErrors?.shipment_type_id}`)}
              </div>
            )}
            {!shippingTypes && <Loading></Loading>}
            {shippingTypes &&
              shippingTypes.map((shippingType, index) => (
                <Checkbox
                  key={index}
                  description={shippingType.description}
                  checked={orderForm?.shipment_type_id === shippingType.id}
                  checkCallback={() =>
                    setOrderForm((prev) => ({
                      ...prev,
                      shipment_type_id: shippingType.id,
                    }))
                  }
                  price={shippingType.price}
                >
                  {shippingType.name}
                </Checkbox>
              ))}
          </div>

          <div className="flex flex-col w-full gap-2 shadow bg-bg-primary2 rounded-sm p-2">
            <span className="font-bold text-lg mt-1">Fizetési forma</span>
            {orderFormErrors?.payment_type_id && (
              <div className="text-color-error text-sm mb-2">
                {t(`error.${orderFormErrors?.payment_type_id}`)}
              </div>
            )}
            {!paymentTypes && <Loading></Loading>}
            {paymentTypes &&
              paymentTypes.map((paymentType, index) => (
                <Checkbox
                  key={index}
                  description={paymentType.description}
                  checked={orderForm?.payment_type_id === paymentType.id}
                  checkCallback={() =>
                    setOrderForm((prev) => ({
                      ...prev,
                      payment_type_id: paymentType.id,
                    }))
                  }
                  price={paymentType.price}
                >
                  {paymentType.name}
                </Checkbox>
              ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col max-w-[80%] w-[80%] gap-2 bg-bg-primary2 shadow rounded-sm p-2 mt-10">
        <span>Megjegyzés</span>
        <TextArea
          placeholder="Megjegyzés"
          value={orderForm.comment}
          change={(value) =>
            setOrderForm((prev) => ({ ...prev, comment: value }))
          }
          errorKey={orderFormErrors?.comment}
        ></TextArea>
      </div>

      <div className="flex flex-col w-full gap-2  max-w-[80%] mt-10 bg-bg-primary2 rounded-sm shadow p-2 mb-2">
        <span className="font-bold text-lg mt-1">Rendelés összegzése</span>
        Termékek
        {cart.map((cartItem, index) => (
          <div key={index} className="bg-bg-primary shadow p-2 w-fit">
            {cartItem.name} x {cartItem.quantity} -{" "}
            {cartItem.unit_price * cartItem.quantity} Ft
          </div>
        ))}
        <div className="flex flex-col">
          <span>Összeg: {cartTotalPrice} Ft</span>
          <span>Szállítási költség: {getShipmentPrice()} Ft</span>
          <span>Utánvét költsége: {getPaymentPrice()} Ft</span>
          <span>Csomag súlya: {0} g</span>
          <strong>Végösszeg: {getFinalPrice()} Ft</strong>
        </div>
      </div>

      <Button className="w-1/2" onClick={() => submitOrder()}>
        Rendelés leadása
      </Button>
    </div>
  );
}
export default Checkout;
