import { useEffect, useState } from "react";
import { Product } from "src/data/Product";
import ProductItem from "src/pages/product-list/ProductItem";
import apiService from "src/shared/apiService/ApiService";

function ProductList() {
  const [productList, setProductList] = useState<Product[]>([]);

  useEffect(() => {
    apiService.get<Product[]>("/product/all").then((products) => {
      setProductList(products);
    });
  }, []);

  return (
    <div className="p-2">
      <span>Termékek</span>
      <div className="flex flex-row gap-2 flex-wrap">
        {productList.map((product, index) => (
          <ProductItem key={index} product={product} />
        ))}
      </div>
    </div>
  );
}
export default ProductList;
