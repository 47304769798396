import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { Popup } from "src/components/popup/Popup";

interface PopupContextType {
  openPopup: (title: string, content: ReactNode | string) => void;
  closePopup: () => void;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const PopupProvider = (props: { children: ReactNode }) => {
  const [popup, setPopup] = useState<ReactNode | string>(false);
  const [title, setTitle] = useState<string>("");

  const closePopup = useCallback(() => {
    setPopup(false);
  }, [setPopup]);

  const openPopup = useCallback(
    (title: string, content: ReactNode | string) => {
      setTitle(title);
      setPopup(content);
    },
    [setPopup]
  );

  return (
    <PopupContext.Provider value={{ openPopup, closePopup }}>
      {props.children}
      {popup && (
        <Popup
          popupContent={popup}
          popupTitle={title}
          closePopup={closePopup}
        />
      )}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);

  if (context === undefined) {
    throw new Error("usePopup must be used within a PopupProvider");
  }

  return context;
};
