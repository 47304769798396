import { t } from "i18next";

export interface InputFieldProps {
  type?: string;
  value?: string | number;
  placeholder?: string;
  change?: (e: any) => void;
  autoComplete?: string;
  error?: string;
  errorKey?: string;
  required?: boolean;
}

function InputField({
  type = "text",
  value,
  placeholder,
  change,
  autoComplete = "off",
  error,
  errorKey,
  required = false,
}: InputFieldProps) {
  return (
    <div>
      <div
        className={`bg-bg-primary rounded-sm shadow text-white px-2 py-1 border-[1px] ${
          error || errorKey
            ? "border-color-error animate-[input-error_0.2s_linear]"
            : "border-text-secondary"
        }`}
      >
        <input
          className="bg-transparent text-text-primary placeholder:text-white border-0 outline-0 autofill:shadow-[inset_0_0_0px_1000px_rgb(242,242,248)] w-full"
          type={type}
          placeholder={placeholder}
          value={value !== undefined ? value : ""}
          required={required}
          autoComplete={autoComplete}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            change && change(e.target.value)
          }
        />
      </div>
      {error && <span className="text-color-error text-sm">{error}</span>}
      {errorKey && (
        <span className="text-color-error text-sm">
          {t(`error.${errorKey}`)}
        </span>
      )}
    </div>
  );
}

export default InputField;
