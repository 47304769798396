import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminPage from "src/admin/AdminPage";

import { useAuth } from "src/components/auth/AuthContext";
import Login from "src/components/auth/login/Login";
import Register from "src/components/auth/register/Register";
import DetailedCartList from "src/components/cart/DetailedCartList";
import Loading from "src/components/loading/Loading";
import Layout from "src/layout/Layout";
import Checkout from "src/pages/checkout/Checkout";
import OrderOverview from "src/pages/checkout/OrderOverview";
import PasswordChange from "src/pages/password-change/PasswordChange";
import ProductList from "src/pages/product-list/ProductList";
import Profile from "src/pages/profile/Profile";
import VerifyRegistration from "src/pages/verify-registration/VerifyRegistration";

function App() {
  const { isLoggedIn, loading } = useAuth();

  if (loading) {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              <div className="w-full h-full flex items-center justify-center">
                <Loading />
              </div>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/admin/*"
          element={isLoggedIn ? <AdminPage /> : <Navigate to="/" />}
        />
        <Route path="/" element={<Layout />}>
          <Route index element={<ProductList />} />
          <Route path="verifyRegistration" element={<VerifyRegistration />} />
          <Route path="passwordChange" element={<PasswordChange />} />
          <Route path="products" element={<ProductList />} />
          <Route path="cart" element={<DetailedCartList />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="orderOverview" element={<OrderOverview />} />

          <Route
            path="login"
            element={isLoggedIn ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="register"
            element={isLoggedIn ? <Navigate to="/" /> : <Register />}
          />
          <Route
            path="profile"
            element={isLoggedIn ? <Profile /> : <Navigate to="/" />}
          />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
