import { useState } from "react";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import apiService from "src/shared/apiService/ApiService";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  function submitForgotPassword() {
    setError("");
    apiService
      .get(`/password?email=${email}`)
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        setError(error.message.email || error.message.password);
      });
  }

  return (
    <div className="w-full">
      {emailSent ? (
        <div>
          <span className="font-semibold text-green-600">
            E-mail elküldve. Ellenőrizd a postaládádat!
          </span>
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          <span>
            Add meg a fiókodhoz tartozó e-mail címet, ahova a
            jelszóváltoztatáshoz szükséges utasításokat tudjuk küldeni.
          </span>
          <InputField
            value={email}
            change={(value) => setEmail(value)}
            placeholder="email"
            errorKey={error}
          ></InputField>
          <Button onClick={() => submitForgotPassword()}>
            Jelszó igénylése
          </Button>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
