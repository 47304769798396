import { ReactNode } from "react";
import { IoClose } from "react-icons/io5";

interface PopupProps {
  popupTitle: string;
  popupContent: ReactNode | string;
  closePopup: () => void;
}

export function Popup({ popupContent, popupTitle, closePopup }: PopupProps) {
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-bg-secondary bg-opacity-60 flex items-center justify-center z-50">
      <div className="bg-bg-primary rounded shadow min-w-96 h-fit p-3 max-w-96">
        <div className="flex flex-row gap-2">
          <span className="flex-1 font-bold mb-2">{popupTitle}</span>
          <IoClose className="text-2xl cursor-pointer" onClick={closePopup} />
        </div>
        <div>{popupContent}</div>
      </div>
    </div>
  );
}
