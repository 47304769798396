import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AdminLayout from "src/admin/layout/AdminLayout";
import Orders from "src/admin/pages/orders/Orders";

function AdminPage() {
  useEffect(() => {
    document.title = "nadovi webadmin";
  }, []);

  return (
    <Routes>
      <Route path="" element={<AdminLayout />}>
        <Route path="orders" element={<Orders />}></Route>
        <Route path="test" element={<Orders />}></Route>
      </Route>
    </Routes>
  );
}

export default AdminPage;
