import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import apiService from "src/shared/apiService/ApiService";

function PasswordChange() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [passwordForm, setPasswordForm] = useState<{
    password: string;
    password_again: string;
  }>({ password: "", password_again: "" });
  const [error, setError] = useState("");
  const [token, setToken] = useState<string | undefined>(undefined);

  const { showAlert } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    const passwordChangeToken = searchParams.get("token");
    if (!passwordChangeToken) {
      navigate("/");
      return;
    }
    setToken(passwordChangeToken);
  }, []);

  function submitPassword() {
    setError("");
    if (passwordForm.password !== passwordForm.password_again) {
      setError("Jelszavak nem egyeznek");
      return;
    }
    apiService
      .put("/password", { token, password: passwordForm.password })
      .then(() => {
        navigate("/");
        showAlert({
          title: "Jelszóváltoztatás",
          description: "Sikeres jelszóváltoztatás. Mostmár bejelentkezhetsz!",
          type: AlertType.SUCCESS,
        });
      })
      .catch((error) => {
        setError(error.message.password);
        if (error.message.token) {
          showAlert({
            title: "Hiba",
            description: "A megadott kulcs érvénytelen vagy lejárt",
            type: AlertType.ERROR,
          });
        }
      });
  }

  return (
    <div className="flex flex-col gap-1 w-fit">
      <span>Add meg az új jelszavad!</span>
      <InputField
        value={passwordForm.password}
        placeholder="password"
        change={(value) =>
          setPasswordForm((prev) => ({ ...prev, password: value }))
        }
        type="password"
      ></InputField>
      <InputField
        value={passwordForm.password_again}
        placeholder="password_again"
        change={(value) =>
          setPasswordForm((prev) => ({
            ...prev,
            password_again: value,
          }))
        }
        type="password"
        errorKey={error}
      ></InputField>
      <Button onClick={() => submitPassword()}>Jelszó mentése</Button>
    </div>
  );
}

export default PasswordChange;
