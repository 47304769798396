import { FaHome, FaShoppingCart } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import { Outlet } from "react-router-dom";
import MenuItem from "src/admin/layout/MenuItem";

function AdminLayout() {
  const menuItems = [
    {
      title: "Vissza az áruházba",
      icon: <FaShop />,
      link: "/",
    },
    {
      title: "Kezdőlap",
      icon: <FaHome />,
      link: "",
    },
    {
      title: "Rendelések",
      icon: <FaShoppingCart />,
      link: "orders",
    },
  ];

  return (
    <div className="flex flex-row bg-bg-primary w-full h-full">
      <nav className="flex h-full bg-bg-secondary shadow w-56 max-w-56 text-color-on-bg-secondary flex-col">
        <div className="flex flex-roe justify-between px-2 py-1">
          <div className="flex flex-col">
            <span className="text-color-primary text-2xl font-extrabold title-font leading-4 mt-1">
              NADOVI
            </span>
            <span className="font-thin text-sm">WEBADMIN</span>
          </div>
        </div>

        <div className="p-2 flex flex-col gap-1">
          {menuItems.map((menuItem, index) => (
            <MenuItem key={index} link={menuItem.link} icon={menuItem.icon}>
              {menuItem.title}
            </MenuItem>
          ))}
        </div>
      </nav>

      <div className="flex-1 flex px-1">
        <Outlet></Outlet>
      </div>
    </div>
  );
}

export default AdminLayout;
