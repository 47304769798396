import { FaCheck, FaExclamation, FaInfo } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { Alert, AlertType } from "src/components/alert/AlertProvider";

interface AlertItemProps {
  alert: Alert;
}

function AlertItem({ alert }: AlertItemProps) {
  return (
    <div className="relative h-fit bg-color-primary text-color-on-primary rounded px-6 py-2 shadow text-white flex flex-row gap-6 w-fit text-sm">
      <div className="flex items-center justify-center">
        {alert.type == AlertType.INFO && <FaInfo />}
        {alert.type == AlertType.SUCCESS && <FaCheck />}
        {alert.type == AlertType.WARNING && <FaExclamation />}
        {alert.type == AlertType.ERROR && <FaXmark />}
      </div>
      <div className="flex flex-col gap-1">
        <span className="font-semibold">{alert.title}</span>
        <span className="font-thin">{alert.description}</span>
      </div>
    </div>
  );
}
export default AlertItem;
