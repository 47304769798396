import { useEffect, useState } from "react";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import Loading from "src/components/loading/Loading";
import apiService from "src/shared/apiService/ApiService";

function VerifyRegistration() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [verifyLoading, setVerifyLoading] = useState(true);
  const { showAlert } = useAlert();

  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = searchParams.get("token");
    if (!verifyToken) {
      navigate("/");
      return;
    }
    apiService
      .put(`/register/verify?token=${verifyToken}`, null)
      .then(() => {
        setVerifyLoading(false);
      })
      .catch(() => {
        showAlert({
          title: "Hiba",
          description: "A megadott kulcs érvénytelen vagy lejárt",
          type: AlertType.ERROR,
        });
        navigate("/");
      });
  }, []);

  return !verifyLoading ? (
    <div className="w-full flex items-center justify-center p-20 flex-col">
      <IoCheckmarkDoneCircle className="text-9xl text-color-primary" />

      <span className="text-xl font-semibold">Sikeres regisztráció</span>
      <span>
        Regisztrációd sikeresen meg lett erősítve. Mostmár bejelentkezhetsz.
      </span>
      <NavLink to={"/login"}>
        <Button className="mt-5">Bejelentkezés</Button>
      </NavLink>
    </div>
  ) : (
    <div className="flex items-center justify-center h-full w-full">
      <Loading></Loading>
    </div>
  );
}
export default VerifyRegistration;
