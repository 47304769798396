import { NavLink } from "react-router-dom";
import { useCart } from "src/components/cart/CartProvider";
import DetailedCartListItem from "src/components/cart/DetailedCartListItem";
import Button from "src/components/form-components/Button";

function DetailedCartList() {
  const { cart, cartTotalPrice } = useCart();

  return (
    <div>
      Kosár tartalma:
      <div className="flex flex-col gap-2 p-2 w-fit">
        {cart.map((cartItem, index) => (
          <DetailedCartListItem key={index} cartItem={cartItem} />
        ))}
      </div>
      <div className="flex flex-col gap-1">
        <span>Rendelés összegzése:</span>
        <span>
          Végösszeg: <strong>{cartTotalPrice} Ft</strong>
        </span>
      </div>
      <NavLink to="/checkout">
        <Button className="w-fit">Tovább a pénztárhoz</Button>
      </NavLink>
    </div>
  );
}

export default DetailedCartList;
