import { FaClock, FaHome, FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { NavLink, Outlet } from "react-router-dom";
import { useAuth } from "src/components/auth/AuthContext";
import SmallCartList from "src/components/cart/SmallCartList";
import Button from "src/components/form-components/Button";
import apiService from "src/shared/apiService/ApiService";

function Layout() {
  const { logout, isLoggedIn } = useAuth();

  function handleLogout() {
    apiService
      .post("/logout", null)
      .then(() => {
        localStorage.removeItem("id");
        localStorage.removeItem("login_token");
        logout();
      })
      .catch(() => {});
  }

  return (
    <div className="flex flex-col w-full h-full bg-bg-primary overflow-auto">
      <header className="w-full min-h-12 bg-bg-secondary shadow flex flex-row gap-20 items-center justify-center text-color-on-bg-secondary text-sm">
        <div className="flex flex-row gap-5">
          <span className="flex flex-row gap-2 items-center">
            <FaPhone className="text-color-primary" />
            +36 30 2222 5555
          </span>
          <span className="flex flex-row gap-2 items-center">
            <FaClock className="text-color-primary" />
            HÉTFŐ - PÉNTEK: 08 - 17
          </span>
          <span className="flex flex-row gap-2 items-center">
            <IoMail className="text-color-primary" />
            info@nadovi.hu
          </span>
        </div>
        <div className="flex flex-row gap-20 items-center">
          <SmallCartList />
          <div className="flex flex-ro gap-1">
            <NavLink to={"/"}>
              <Button className="h-full text-lg" icon={<FaHome />}></Button>
            </NavLink>
            {!isLoggedIn ? (
              <NavLink to={"/login"}>
                <Button>Bejelentkezés</Button>
              </NavLink>
            ) : (
              <>
                <NavLink to={"/profile"}>
                  <Button>Profilom</Button>
                </NavLink>
                <Button onClick={() => handleLogout()}>Kijelentkezés</Button>
              </>
            )}
          </div>
        </div>
      </header>
      <div className="flex-1">
        <Outlet />
      </div>
      <footer className="bg-bg-secondary shadow w-full h-fit px-2 py-5 text-color-on-bg-secondary flex flex-row gap-20 justify-center">
        <div className="w-96 flex flex-col gap-2">
          <h1 className="font-extrabold text-4xl title-font">
            NADOVI AUTÓSZERVIZ
          </h1>
          <span className="font-thin">
            Szervizünk elkötelezett a minőségi és korrekt munka iránt
          </span>
          <span>Véleménye fontos számunkra és hasznos lehet másoknak is.</span>
          <span className="font-semibold">Ossza meg velünk!</span>
          <Button className="w-fit">Vélemény írása</Button>
        </div>
        <div className="max-w-96 flex flex-col gap-2">
          <strong>Telephelyünk</strong>
          <span className="font-thin">
            2000 Szentendre, Dózsa György út 22.
          </span>
          <strong>Nyitvatartás</strong>
          <span className="font-thin">Hétfő-Péntek 08-17 óráig</span>
          <strong>E-mail cím</strong>
          <span className="font-thin">info@nadovi.hu</span>
          <strong>Telefonszám</strong>
          <span className="font-thin">+36 20 444 3006</span>
        </div>
        <div className="max-w-96 flex flex-col gap-2">
          <strong className="text-xl">Gyors elérés</strong>
          <span className="font-thin">Valami 1</span>
          <span className="font-thin">Valami 2</span>
          <span className="font-thin">Valami 3</span>
        </div>
        <div className="max-w-96 flex flex-col gap-2">
          <strong className="text-xl">Információk</strong>
          <span className="font-thin">Valami 1</span>
          <span className="font-thin">Valami 2</span>
          <span className="font-thin">Valami 3</span>
        </div>
      </footer>
    </div>
  );
}
export default Layout;
