import { MdDelete, MdOutlineAdd, MdOutlineRemove } from "react-icons/md";
import { useCart } from "src/components/cart/CartProvider";
import { CartItem } from "src/data/Cart";

interface DetailedCartListItemProps {
  cartItem: CartItem;
}

function DetailedCartListItem({ cartItem }: DetailedCartListItemProps) {
  const { cart, cartTotalPrice, removeFromCart, addToCart, decreaseQuantity } =
    useCart();

  return (
    <div className="bg-gray-200 rounded shadow p-2 ">
      {cartItem.name} ({cartItem.quantity} x {cartItem.unit_price} Ft)
      <div className="flex flex-row gap-1">
        <div onClick={() => decreaseQuantity(cartItem)}>
          <MdOutlineRemove />
        </div>
        <div>{cartItem.quantity}</div>
        <div onClick={() => addToCart(cartItem)}>
          <MdOutlineAdd />
        </div>
        <div
          className="text-lg cursor-pointer"
          onClick={() => removeFromCart(cartItem.product_id)}
        >
          <MdDelete />
        </div>
      </div>
    </div>
  );
}

export default DetailedCartListItem;
