import { useEffect, useState } from "react";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import Loading from "src/components/loading/Loading";
import { OrderOverviewData } from "src/data/Order";
import { ProfileData } from "src/data/Profile";
import apiService from "src/shared/apiService/ApiService";

function Profile() {
  const [profileDataForm, setProfileDataForm] = useState<ProfileData>();
  const { showAlert } = useAlert();
  const [profileFormErrors, setProfileFormErrors] = useState<
    Partial<ProfileData> & { floor_number: string }
  >();
  const [profileSaveLoading, setProfileSaveLoading] = useState(false);
  const [orders, setOrders] = useState<OrderOverviewData[]>([]);

  useEffect(() => {
    apiService.get<ProfileData>("/profile").then((response: ProfileData) => {
      setProfileDataForm(response);
    });
    apiService
      .get<OrderOverviewData[]>("/order")
      .then((response: OrderOverviewData[]) => {
        setOrders(response);
      });
  }, []);

  function saveProflie() {
    setProfileSaveLoading(true);
    setProfileFormErrors(undefined);
    apiService
      .put("/profile", profileDataForm)
      .then(() => {
        showAlert({
          title: "Profil frissítve",
          description: "Profil adatai frissültek",
          type: AlertType.SUCCESS,
        });
        setProfileSaveLoading(false);
      })
      .catch((error) => {
        setProfileFormErrors(error.message);
        setProfileSaveLoading(false);
      });
  }

  return profileDataForm ? (
    <div className="flex flex-row gap-2 p-2">
      <div className="flex flex-col gap-2 w-fit p-2">
        <span>Profil adatok </span>
        <span>Személyes adatok</span>
        <div className="flex flex-row gap-2">
          <InputField
            placeholder="Vezetkénév"
            value={profileDataForm?.last_name}
            errorKey={profileFormErrors?.last_name}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                last_name: value,
              }))
            }
          ></InputField>
          <InputField
            placeholder="Keresztnév"
            value={profileDataForm?.first_name}
            errorKey={profileFormErrors?.first_name}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                first_name: value,
              }))
            }
          ></InputField>
        </div>
        <InputField
          placeholder="E-mail cím"
          value={profileDataForm?.email}
          errorKey={profileFormErrors?.email}
          change={(value) =>
            setProfileDataForm((prev: any) => ({
              ...prev,
              email: value,
            }))
          }
        ></InputField>
        <InputField
          placeholder="Telefonszám"
          value={profileDataForm?.phone_number}
          errorKey={profileFormErrors?.phone_number}
          change={(value) =>
            setProfileDataForm((prev: any) => ({
              ...prev,
              phone_number: value,
            }))
          }
        ></InputField>
        <span>Számlázási adatok</span>
        <div className="flex flex-row gap-2">
          <InputField
            placeholder="Irányítószám"
            value={profileDataForm?.zip_code}
            errorKey={profileFormErrors?.zip_code}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                zip_code: value,
              }))
            }
          ></InputField>
          <InputField
            placeholder="Város"
            value={profileDataForm?.city}
            errorKey={profileFormErrors?.city}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                city: value,
              }))
            }
          ></InputField>
        </div>
        <InputField
          placeholder="Cím"
          value={profileDataForm?.street_address}
          errorKey={profileFormErrors?.street_address}
          change={(value) =>
            setProfileDataForm((prev: any) => ({
              ...prev,
              street_address: value,
            }))
          }
        ></InputField>
        <div className="flex flex-row gap-2">
          <InputField
            placeholder="Emelet"
            value={profileDataForm?.floor_number}
            errorKey={profileFormErrors?.floor_number}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                floor_number: value,
              }))
            }
          ></InputField>
          <InputField
            placeholder="Ajtó"
            value={profileDataForm?.door_number}
            errorKey={profileFormErrors?.door_number}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                door_number: value,
              }))
            }
          ></InputField>
        </div>
        <Button loading={profileSaveLoading} onClick={saveProflie}>
          Mentés
        </Button>
      </div>

      <div>
        <h1>Rendelések</h1>
        <div className="flex flex-row gap-2">
          {orders?.map((order) => (
            <div className="flex flex-col bg-bg-primary2 rounded-sm shadow p-2">
              <span>{order.id}</span>
              <span>{order.total_price} Ft</span>
              <span>{order.total_weight} g</span>
              <span>{order.products.length} termék</span>
              <span>{order.order_status}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
}

export default Profile;
