import { BsThreeDots } from "react-icons/bs";

function Orders() {
  return (
    <div className="w-full px-10 py-2">
      <table className="w-full divide-y divide-table-border border border-table-border">
        <thead className="bg-bg-secondary text-color-on-bg-secondary">
          <tr>
            <th>Rendelés azonosítója</th>
            <th>Rendelés ideje</th>
            <th>Státusz</th>
            <th>Kapcsolat</th>
            <th>Ár</th>
            <th>Csomag súlya</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-table-border">
          <tr>
            <td>2k3jh4g52k3j4</td>
            <td>2024. 10. 04 07:33</td>
            <td>Kézbesítve</td>
            <td>
              <div className="flex flex-col">
                <span>szandasoma@gmail.com</span>
                <span>+36302386803</span>
              </div>
            </td>
            <td>56 234 Ft</td>
            <td>33455 g</td>
            <td>
              <BsThreeDots />
            </td>
          </tr>
          <tr>
            <td>2k3jh4g52k3j4</td>
            <td>2024. 10. 04 07:33</td>
            <td>Kézbesítve</td>
            <td>
              <div className="flex flex-col">
                <span>szandasoma@gmail.com</span>
                <span>+36302386803</span>
              </div>
            </td>
            <td>56.234 Ft</td>
            <td>33455 g</td>
            <td>
              <BsThreeDots />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default Orders;
