import { environment } from "src/environment";

const HEADERS = {
  "Content-Type": "application/json",
};

enum API_METHOD {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

class ApiService {
  constructor() {}

  private getHeaders(): { [key: string]: string } {
    const loginToken = localStorage.getItem("login_token") ?? undefined;
    const id = localStorage.getItem("id") ?? undefined;

    if (!loginToken || !id) {
      return HEADERS;
    } else {
      return { ...HEADERS, ...{ token: loginToken, id: id } };
    }
  }

  /**
   * Get
   * @param url
   * @returns
   */
  get<T>(url: string) {
    return new Promise<T>((resolve, reject) => {
      fetch(`${environment.serverUrl}${url}`, {
        method: API_METHOD.GET,
        headers: this.getHeaders(),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((response) => reject(response));
          } else {
            return response.json();
          }
        })
        .then((response: T) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  /**
   * Post
   * @param url
   * @param data
   * @returns
   */
  post<T>(url: string, data: any) {
    return new Promise<T>((resolve, reject) => {
      fetch(`${environment.serverUrl}${url}`, {
        method: API_METHOD.POST,
        headers: this.getHeaders(),
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((response) => reject(response));
          } else {
            return response.json();
          }
        })
        .then((response: T) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  /**
   * Put
   * @param url
   * @param data
   * @returns
   */
  put(url: string, data: any) {
    return new Promise((resolve, reject) => {
      fetch(`${environment.serverUrl}${url}`, {
        method: API_METHOD.PUT,
        headers: this.getHeaders(),
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((response) => reject(response));
          } else {
            return response.json();
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  /**
   * Delete
   * @param url
   * @returns
   */
  delete(url: string, data: any) {
    return new Promise((resolve, reject) => {
      fetch(`${environment.serverUrl}${url}`, {
        headers: this.getHeaders(),
        method: API_METHOD.DELETE,
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((response) => reject(response));
          } else {
            return response.json();
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

const apiService = new ApiService();

export default apiService;
