import { ReactNode } from "react";
import { FaRegSquare, FaSquareCheck } from "react-icons/fa6";

export interface CheckboxProps {
  children: ReactNode;
  checked?: boolean;
  description?: string;
  checkCallback?: Function;
  price?: number;
}

export default function Checkbox({
  children,
  checked = false,
  checkCallback,
  description,
  price,
}: CheckboxProps) {
  return (
    <div>
      <label className="flex relative cursor-pointer ps-6 select-none w-fit group flex-col">
        <div className="flex flex-row items-center">
          {children}
          <input
            type="checkbox"
            className="absolute opacity-0 h-0 w-0"
            checked={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (checkCallback) {
                checkCallback(e.target.checked);
              }
            }}
          />
          {!checked && (
            <div className="absolute left-0 text-black text-lg">
              <FaRegSquare />
            </div>
          )}
          {checked && (
            <div className="absolute left-0 text-black text-lg">
              <FaSquareCheck />
            </div>
          )}
        </div>
        {description && (
          <span className="text-text-secondary font-thin">{description}</span>
        )}
        {price !== undefined && (
          <span className="text-color-primary">{price} Ft</span>
        )}
      </label>
    </div>
  );
}
