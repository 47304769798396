import { t } from "i18next";

export interface TextAreaProps {
  value?: string;
  placeholder?: string;
  change?: (e: any) => void;
  error?: string;
  errorKey?: string;
  required?: boolean;
  rows?: number;
}

function TextArea({
  value,
  placeholder,
  change,
  error,
  errorKey,
  required = false,
  rows = 3,
}: TextAreaProps) {
  return (
    <div>
      <div
        className={`bg-bg-primary rounded-sm shadow text-white px-2 py-1 border-[1px] ${
          error || errorKey
            ? "border-color-error animate-[input-error_0.2s_linear]"
            : "border-text-secondary"
        }`}
      >
        <textarea
          className="bg-transparent text-white placeholder:text-white border-0 outline-0 autofill:shadow-[inset_0_0_0px_1000px_rgb(156,163,175)] w-full"
          placeholder={placeholder}
          value={value}
          required={required}
          rows={rows}
          onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            change && change(e.target.value)
          }
        />
      </div>
      {error && <span className="text-red-700 text-sm">{error}</span>}
      {errorKey && (
        <span className="text-red-700 text-sm">{t(`error.${errorKey}`)}</span>
      )}
    </div>
  );
}

export default TextArea;
