import i18next from "i18next";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { AlertProvider } from "src/components/alert/AlertProvider";
import { AuthProvider } from "src/components/auth/AuthContext";
import { CartProvider } from "src/components/cart/CartProvider";
import { PopupProvider } from "src/components/popup/ModalContext";
import App from "./App";
import hu from "./assets/translations/hu.json";
import "./index.css";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "hu",
  defaultNS: "translation",
  resources: {
    hu: { translation: hu },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  //   <React.StrictMode>
  <AuthProvider>
    <AlertProvider>
      <CartProvider>
        <I18nextProvider i18n={i18next}>
          <PopupProvider>
            <App />
          </PopupProvider>
        </I18nextProvider>
      </CartProvider>
    </AlertProvider>
  </AuthProvider>
  //   </React.StrictMode>
);
