import { FaCartPlus } from "react-icons/fa";
import { useCart } from "src/components/cart/CartProvider";
import Button from "src/components/form-components/Button";
import { Product } from "src/data/Product";

interface ProductItemProps {
  product: Product;
}

function ProductItem({ product }: ProductItemProps) {
  const { addToCart } = useCart();

  function addProductToCart() {
    addToCart({
      name: product.name,
      product_id: product.product_id,
      quantity: 1,
      unit_price: product.price,
    });
  }

  return (
    <div className="bg-bg-primary2 rounded-sm p-2 min-w-60 w-10 flex flex-col">
      <span className="text-xl font-semibold">{product.name}</span>
      <span className="">{product.price} Ft</span>
      <span className="">({product.product_id})</span>
      <div className="mt-2">
        <Button icon={<FaCartPlus />} onClick={() => addProductToCart()}>
          Kosárhoz adás
        </Button>
      </div>
    </div>
  );
}

export default ProductItem;
