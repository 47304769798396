import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface MenuItemProps {
  children: ReactNode;
  icon: ReactNode;
  link: string;
}

function MenuItem({ children, icon, link }: MenuItemProps) {
  return (
    <NavLink
      to={link}
      end
      className={({ isActive }) =>
        `px-2 py-1 rounded flex flex-row items-center gap-2 before:content-[''] before:rounded before:w-1 before:bg-color-on-bg-secondary hover:bg-bg-secondary-hover before:transition-all before:duration-200 ` +
        (isActive ? "font-bold before:h-4" : "before:h-0 text-text-secondary")
      }
    >
      <div className="text-lg">{icon}</div>
      <span className="flex-1">{children}</span>
    </NavLink>
  );
}

export default MenuItem;
